<template>
	<div class="sf-main">
		<div class="sf-head">
			<h3>Form Preview</h3>
		</div>
		<div class="sf-cnt">
			<div class="sf-cnt-top">
				<div class="sf-cnt-top__ellipses">
					<div class="sf-cnt-top__ellipse"></div>
					<div class="sf-cnt-top__ellipse"></div>
					<div class="sf-cnt-top__ellipse"></div>
				</div>
			</div>
			<div :class="` ${mainClass}`" :style="{backgroundColor: this.type === 'landing' ? formSettings.backgroundColor : '' }">
				<Form :settings="formSettings" :type="type" :onSubmit="() => {}"></Form>
			</div>
		</div>

	</div>

</template>

<script>
    import Form from "../../../../../../shared/components/Form";
    export default {
        name: "FormPreview",
        components: {Form},
        props: {
            type: {
                type: String,
				required: true,
			},
            formSettings: {
                type: Object,
				required: true
			}
		},
		computed: {
            mainClass() {
                return 'sf-cnt-main' + (this.type === 'landing' ? '--wheader' : (this.type === 'popup' ? '--popup sf-cnt-main ' : ''));
            }
		}
    }
</script>
<style lang="scss">
	@import "../../../../../../shared/assets/styles/blocks/setup-audit-form";
	.sf-cnt-main--wheader {
		min-height: 600px;
		border-top-left-radius: 8px;
		border-top-right-radius: 8px;
	}
</style>
